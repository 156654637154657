import { useCallback, useMemo } from 'react';
import { IConversation } from '../../../interfaces/conversation/conversation.model';
import { useQueryClient } from 'react-query';
import { useConversationsLoaderState } from '../state/conversationsLoader.state';

export const useConversationHandleStore = () => {
    const queryClient = useQueryClient();

    const { lastOptimisticUpdate } = useConversationsLoaderState();

    const conversationsStoreUpdate = useCallback(
        (conversations: IConversation[]) => {
            return queryClient.setQueryData(['conversations-storage'], () => {
                return conversations;
            });
        },
        [queryClient]
    );

    const conversations: IConversation[] = useMemo(() => {
        if (!lastOptimisticUpdate) {
            /* Must be empty, but set for update */
        }
        return Object.values(
            queryClient.getQueryData<{ [key: number]: IConversation }>(['conversations-storage']) ??
                {}
        );
    }, [lastOptimisticUpdate, queryClient]);

    return { conversationsStoreUpdate, conversations };
};
