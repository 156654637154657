import { useCallback } from 'react';
import { IConversation } from '../../../interfaces/conversation/conversation.model';
import { IMessageInQueue } from '../../../components/Chat/interfaces/messageInQueue.interface';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../components/Auth/userAtom';
import { useQueryClient } from 'react-query';
import { useConversationsLoaderState } from '../state/conversationsLoader.state';

export const useChatMessageOptimisticUpdate = () => {
    const queryClient = useQueryClient();
    const { setLastOptimisticUpdate } = useConversationsLoaderState();

    const user = useRecoilValue(userAtom);

    const updateMessageOptimisticToBeenSent = useCallback(
        (item: IMessageInQueue) => {
            const sentDate = new Date().toISOString();

            // Update Conversation ID in Query
            const conversationId = item.conversationId;

            // conversations-storage
            queryClient.setQueryData(['conversations-storage'], (old?: IConversation[]) => {
                if (old) {
                    const convos = [...old];
                    const messageConversationIndex = convos.findIndex(
                        (convo) => convo.id === conversationId
                    );

                    const updatedConversation = convos[messageConversationIndex];

                    if (updatedConversation) {
                        const messagesUpdated = updatedConversation.messages.map((message) =>
                            message.id === item.tempMessageId
                                ? {
                                      ...message,
                                      id: item.tempMessageId,
                                      sent: sentDate,
                                  }
                                : message
                        );

                        const currentConversationsStore: IConversation = {
                            ...updatedConversation,
                            messages: messagesUpdated,
                        };

                        if (old[messageConversationIndex]) {
                            convos[messageConversationIndex] = currentConversationsStore;
                            return convos;
                        }
                    }
                }
            });

            setLastOptimisticUpdate();
        },

        [queryClient, setLastOptimisticUpdate]
    );

    const addOptimisticMessage = useCallback(
        (item: IMessageInQueue) => {
            const conversationId = item.conversationId;

            // Add to conversation-store key
            queryClient.setQueryData(
                ['conversations-storage'],
                (old?: { [key: number]: IConversation }) => {
                    if (old) {
                        const convos = [...Object.values(old)];
                        const messageConversationIndex = convos.findIndex(
                            (convo) => convo.id === conversationId
                        );

                        const updatedConversation = convos[messageConversationIndex];

                        if (updatedConversation) {
                            const currentConversationsStore: IConversation = {
                                ...updatedConversation,
                                messages: [
                                    ...updatedConversation.messages,
                                    {
                                        id: item.tempMessageId,
                                        author: {
                                            ...user,
                                        },
                                        automated: false,
                                        body: item.body,
                                        sent: undefined,
                                        read: [],
                                        received: [],
                                        deleted: false,
                                    },
                                ],
                            };

                            if (old[messageConversationIndex]) {
                                convos[messageConversationIndex] = currentConversationsStore;

                                return convos;
                            }
                        }
                    }
                }
            );

            setLastOptimisticUpdate();
        },
        [queryClient, setLastOptimisticUpdate, user]
    );

    return { addOptimisticMessage, updateMessageOptimisticToBeenSent };
};
