import { useMutation, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import { useApi } from '../api/useApi';
import { IConversationMessage } from '../../interfaces/conversation/convesationMessage.model';
import { messageOnSuccess } from './queryKeyFactory';

export interface IConversationAddMessageInput {
    id: string; // This is the conversationId
    body: string;
}

export const useConversationAddMessages = () => {
    const queryClient = useQueryClient();
    const { postGql } = useApi();

    return useMutation(
        async (inputs: IConversationAddMessageInput[]) => {
            const { addConversationMessages } = await postGql<{
                addConversationMessages: IConversationMessage[];
            }>(
                gql`
                    mutation ($input: [ConversationAddMessageInput!]!) {
                        addConversationMessages(input: $input) {
                            id
                            author {
                                id
                                name
                                email
                            }
                            body
                            sent
                            automated
                            read {
                                id
                                name
                                email
                            }
                            received {
                                id
                                name
                                email
                            }
                            deleted
                        }
                    }
                `,
                { input: inputs },
                'addConversationMessages'
            );

            return addConversationMessages;
        },
        {
            onSuccess: (messages) => {
                // Handle success events for the batch of messages
                messages.forEach((message) => messageOnSuccess(queryClient, message.id, message));
            },
        }
    );
};
