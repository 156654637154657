import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { getUnreadCount } from '../../hooks/conversation/helper/getUnreadAllCount';
import { useUser } from '../../hooks/user/useUser';
import { useFilteredConversations } from './hooks/useFilteredConversations';
import Link from 'next/link';
import { useAdminSVSceneDetector } from '../../hooks/useAdminSVSceneDetector';
import { useChatHasQueuedMessages } from '../../hooks/conversation/hooks/useChatHasQueuedMessages';

interface IChatMessageIndicator {
    envelopeColor?: 'primary' | 'white';
}

export const ChatMessageIndicator: React.FC<IChatMessageIndicator> = (props) => {
    const { envelopeColor = 'white' } = props;

    const { isAdminPage } = useAdminSVSceneDetector();

    const { queuedMessages } = useChatHasQueuedMessages();

    const { data: user } = useUser();
    const { conversationsFiltered } = useFilteredConversations();

    const count = useMemo(() => {
        if (!user || !user?.id) {
            return 0;
        }

        return getUnreadCount(user?.id, conversationsFiltered);
    }, [conversationsFiltered, user]);

    const bgColor: string = useMemo(() => {
        if (envelopeColor === 'white') return 'text-white';

        return 'text-primary';
    }, [envelopeColor]);

    const href: string = useMemo(() => {
        if (isAdminPage) {
            return '/admin/clientchat/';
        }

        return '/clientchat/';
    }, [isAdminPage]);

    return (
        <Link href={href} passHref>
            <a className="relative flex h-4 w-4 items-center justify-center rounded-full">
                {count > 0 && (
                    <>
                        <span className="absolute -right-3 -top-2 flex aspect-[1/1] h-full w-full animate-ping rounded-full bg-success px-1" />
                        <span className="absolute -right-4 -top-2 flex aspect-[1/1] h-full w-full rounded-full bg-success px-1 text-center text-xs font-semibold text-white">
                            {count ?? 0}
                        </span>
                    </>
                )}
                <FontAwesomeIcon icon={faEnvelope} className={`h-5 ${bgColor}`} />

                {queuedMessages > 0 && (
                    <figure className="absolute -top-1.5 right-2.5 flex h-4 w-4 animate-bounce items-center justify-center rounded-full bg-blue-500">
                        <span className="text-xs text-white">{queuedMessages}</span>
                    </figure>
                )}
            </a>
        </Link>
    );
};
