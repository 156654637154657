import { useConversationsDeltaQuery } from '../../../hooks/conversation/useConversationsDeltaQuery';
import { useConversationsConsistencyQuery } from '../../../hooks/conversation/useConversationsConsistencyQuery';
import { useConversationsConsistencyUpdateQuery } from '../../../hooks/conversation/useConversationsConsistencyUpdateQuery';
import { useDeltaRefreshService } from './useDeltaRefreshService';
import { useConsistencyRefreshService } from './useConsistencyRefreshService';
import { useConversationsPagedQuery } from '../../../hooks/conversation/useConversationsStoreQuery';

export const useChatSystemHandler = () => {
    useConversationsPagedQuery();
    useConversationsDeltaQuery();
    useConversationsConsistencyQuery();
    useConversationsConsistencyUpdateQuery();
    useDeltaRefreshService();
    useConsistencyRefreshService();
    return {};
};
