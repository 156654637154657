import { gql } from 'graphql-request';
import { useQuery, useQueryClient } from 'react-query';
import { IConversation } from '../../interfaces/conversation/conversation.model';
import { useApi } from '../api/useApi';
import dayjs from 'dayjs';
import { useConversationHandleStore } from './hooks/useConversationHandleStore';
import { useConversationsLoaderState } from './state/conversationsLoader.state';

export interface IConversations {
    total: number;
    count: number;
    conversations: IConversation[];
}

export const useConversationsPagedQuery = () => {
    const { postGql } = useApi();
    const { conversationsStoreUpdate: setConversations } = useConversationHandleStore();
    const { setLastOptimisticUpdate } = useConversationsLoaderState();

    const queryClient = useQueryClient();

    return useQuery({
        enabled: true,
        queryKey: ['conversations-storage'],
        queryFn: async () => {
            const take = 50; // Number of items to fetch per request
            let skip = 0; // Start fetching from index 0
            let allConversations: IConversation[] = [];
            let totalRecords = 0;

            do {
                // Fetch the next batch of conversations
                const { conversationsPaged } = await postGql<{
                    conversationsPaged: IConversations;
                }>(
                    gql`
                        query conversationsPaged($input: ConversationsPagedInput!) {
                            conversationsPaged(input: $input) {
                                total
                                count
                                conversations {
                                    id
                                    subject
                                    contractID
                                    contract {
                                        id
                                        express
                                        fileNumber
                                        address {
                                            street
                                            city
                                            postalCode
                                            country
                                        }
                                        company {
                                            name
                                        }
                                    }
                                    recipients {
                                        id
                                        name
                                        email
                                        roles {
                                            name
                                            access
                                            admin
                                        }
                                        fields {
                                            firstName
                                            lastName
                                            absenceFrom
                                            absenceTill
                                        }
                                    }
                                    messages {
                                        id
                                        sent
                                        deleted
                                        automated
                                        body
                                        author {
                                            id
                                            name
                                            email
                                            fields {
                                                firstName
                                                lastName
                                            }
                                        }
                                        read {
                                            id
                                        }
                                        received {
                                            id
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    { input: { take, skip } },
                    'conversations-storage'
                );

                // Append results incrementally into the state
                allConversations = [...allConversations, ...conversationsPaged.conversations];
                setConversations(allConversations);

                // Update totalRecords for ending the loop condition#
                totalRecords = conversationsPaged.total;

                // Increment the offset (skip) for the next fetch
                skip += take;

                const updatedConversations = [...allConversations];

                queryClient.setQueryData<IConversations | undefined>(
                    ['conversations-storage'],
                    (oldData) => {
                        return {
                            ...(oldData || { total: 0, count: 0, conversations: [] }),
                            conversations: updatedConversations,
                        };
                    }
                );
            } while (allConversations.length < totalRecords);

            // Update the last optimistic update timestamp - To make initial loadings possible
            setLastOptimisticUpdate();

            return allConversations;
        },
        onSuccess: () => {
            localStorage.setItem('conversationsLastSync', dayjs().toISOString());
        },
        // 3 Days
        staleTime: 60 * 60 * 24 * 3,
    });
};
