import { create } from 'zustand';
import { IConversation } from '../../../interfaces/conversation/conversation.model';

interface IConversationsLoader {
    conversationsFiltered: IConversation[];
    setConversationsFiltered: (state: IConversation[]) => void;
    storeLoaded: boolean;
    setStoreLoaded: (state: boolean) => void;
    skip: number;
    take: number;
    setSkip: (state: number) => void;
    setTake: (state: number) => void;
    addSkip: (state: number) => void;
    set: (state: Partial<IConversationsLoader>) => void;
    conversationsToUpdate: number[];
    setConversationsToUpdate: (state: number[]) => void;
    lastSyncTimestamp?: string | null;
    setLastSyncTimestamp: (state: string) => void;
    lastOptimisticUpdate: string;
    setLastOptimisticUpdate: () => void;
}

export const useConversationsLoaderState = create<IConversationsLoader>((set, get) => ({
    conversationsFiltered: [],
    storeLoaded: false,
    setStoreLoaded: (state) => {
        set({ storeLoaded: state });
    },
    setConversationsFiltered: (state) => {
        set({ conversationsFiltered: state });
    },
    skip: 0,
    take: 500,
    addSkip: (state) => {
        set({ skip: get().skip + state });
    },
    setSkip: (state) => {
        set({ skip: state });
    },
    setTake: (state) => {
        set({ take: state });
    },
    set: (state) => {
        set(state);
    },
    conversationsToUpdate: [],
    setConversationsToUpdate: (state) => {
        set({ conversationsToUpdate: state });
    },
    lastSyncTimestamp: null,
    setLastSyncTimestamp: (state) => {
        set({ lastSyncTimestamp: state });
    },
    lastOptimisticUpdate: '',
    setLastOptimisticUpdate: () => {
        set({
            lastOptimisticUpdate: new Date().toISOString(),
        });
    },
}));
