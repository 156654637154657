import { create } from 'zustand';

interface IChatMessageQueue {
    lastSync: string;
    setLastSync: (lastSync: string) => void;
    // messages: IMessageInQueue[];
    // addMessage: (message: IMessageInQueue) => Promise<void>;
    // setMessages: (messages: IMessageInQueue[]) => void;
    set: (state: Partial<IChatMessageQueue>) => void;
}

export const useChatMessageQueue = create<IChatMessageQueue>((set) => ({
    lastSync: '',
    setLastSync: (lastSync) => {
        set({ lastSync });
    },
    // setMessages: (messages) => {
    //     set({ messages });
    // },
    // messages: [],
    // addMessage: async (message) => {
    //     set((state) => {
    //         return { messages: [...state.messages, message] };
    //     });
    //
    //     if (typeof window !== 'undefined') {
    //         await lfChatMessageQueue.setItem(new Date().toISOString(), message);
    //     }
    // },
    set: (state) => {
        set(state);
    },
}));
