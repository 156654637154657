import { useConversationsDeltaQuery } from '../../../hooks/conversation/useConversationsDeltaQuery';
import { useEffect } from 'react';

export const useDeltaRefreshService = () => {
    const { isFetching: isFetchingMessages, refetch: refetchMessages } =
        useConversationsDeltaQuery();

    useEffect(() => {
        const interval = setInterval(() => {
            if (isFetchingMessages) {
                return;
            }

            void refetchMessages();
        }, 30000);

        return () => {
            clearInterval(interval);
        };
    }, [isFetchingMessages, refetchMessages]);
};
