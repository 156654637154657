import { useCallback } from 'react';
import { IMessageInQueue } from '../interfaces/messageInQueue.interface';
import { lfChatMessageQueue } from '../localstorage/lfAdminContracts';
import {
    IConversationAddMessageInput,
    useConversationAddMessages,
} from '../../../hooks/conversation/useConversationAddMessages';
import { useChatMessageOptimisticUpdate } from '../../../hooks/conversation/hooks/useChatMessageOptimisticUpdate';
import useOnlineStatus from '../../../hooks/useOnlineStatus';

const maxItemsToSendPerBatch = 10;

export const useChatMessageQueueUpdate = () => {
    const isOnline = useOnlineStatus();

    const { mutateAsync: sendMessages, isLoading: isLoadingSending } = useConversationAddMessages();

    const { updateMessageOptimisticToBeenSent } = useChatMessageOptimisticUpdate();

    const processOneBatchOfQueuedItems = useCallback(async () => {
        if (isLoadingSending) return;

        try {
            if ((await lfChatMessageQueue.length()) === 0) return [];

            const items: IMessageInQueue[] = [];
            let count = 0;

            await lfChatMessageQueue.iterate((value: IMessageInQueue, key) => {
                if (count < maxItemsToSendPerBatch) {
                    items.push({ ...value, key } as IMessageInQueue);
                    count += 1;
                }

                if (count >= maxItemsToSendPerBatch) {
                    return [];
                }
            });

            const mappedItems: IConversationAddMessageInput[] = items.map((item) => {
                return {
                    id: item.conversationId,
                    body: item.body,
                };
            });

            await sendMessages(mappedItems).then((response) => {
                if ('error' in response) {
                    console.error('Error sending messages', response);
                    return;
                }

                // Clear from Queue
                items.forEach((item) => {
                    if (!item.key) return;
                    void lfChatMessageQueue.removeItem(item.key);
                });

                items.forEach((item) => {
                    updateMessageOptimisticToBeenSent(item);
                });
            });

            return items;
        } catch (error) {
            console.error('Error fetching batch from IDB:', error);
            return [];
        }
    }, [isLoadingSending, sendMessages, updateMessageOptimisticToBeenSent]);

    const sendMessageBatch = useCallback(async () => {
        if (!isOnline) return;
        await processOneBatchOfQueuedItems();
    }, [isOnline, processOneBatchOfQueuedItems]);

    return { sendMessageBatch };
};
