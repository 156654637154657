'use client';
import { gql } from 'graphql-request';
import { useQuery, useQueryClient } from 'react-query';
import { IConversation } from '../../interfaces/conversation/conversation.model';
import { useApi } from '../api/useApi';
import { useConversationsLoaderState } from './state/conversationsLoader.state';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { conversationSortByNewestMessage } from '../../components/Chat/helper/conversationSortByNewestMessage';

export interface IConversations {
    total: number;
    count: number;
    conversations: IConversation[];
}

export const useConversationsDeltaQuery = () => {
    const { postGql } = useApi();
    const {
        // setConversations,
        storeLoaded,
        lastSyncTimestamp,
        setLastSyncTimestamp,
        setConversationsToUpdate,
    } = useConversationsLoaderState();

    const { setLastOptimisticUpdate } = useConversationsLoaderState();

    const queryClient = useQueryClient();

    const queryState = queryClient.getQueryState(['conversations-storage']);

    const conversationsStoreIsFetching = useMemo(() => {
        return queryState?.fetchStatus === 'fetching';
    }, [queryState?.fetchStatus]);

    const lastSyncDetermined: string | null = useMemo(() => {
        let lastSync: string | null;

        if (lastSyncTimestamp) {
            lastSync = lastSyncTimestamp;
        } else {
            if (typeof window !== 'undefined') {
                lastSync = window.localStorage.getItem('conversationsLastSync');
            } else {
                lastSync = null;
            }
        }

        if (lastSync === null || lastSync === '') {
            // Fallback -1 day
            return dayjs().subtract(1, 'day').toISOString();
        }

        const lastSyncDate = dayjs(lastSync);
        return lastSyncDate.toISOString();
    }, [lastSyncTimestamp]);

    return useQuery({
        enabled: !conversationsStoreIsFetching && storeLoaded,
        queryKey: ['conversations-delta'],
        queryFn: async () => {
            const take = 200; // Number of items to fetch per request
            let skip = 0; // Start fetching from index 0
            let allConversations: IConversation[] = [];
            let totalRecords = 0;

            do {
                // Fetch the next batch of conversations
                const { conversationsPaged } = await postGql<{
                    conversationsPaged: IConversations;
                }>(
                    gql`
                        query conversationsPaged($input: ConversationsPagedInput!) {
                            conversationsPaged(input: $input) {
                                total
                                count
                                conversations {
                                    id
                                    subject
                                    contractID
                                    messages {
                                        id
                                        author {
                                            id
                                            fields {
                                                firstName
                                                lastName
                                            }
                                        }
                                        read {
                                            id
                                        }
                                        sent
                                        deleted
                                        automated
                                        body
                                    }
                                }
                            }
                        }
                    `,
                    {
                        input: {
                            take,
                            skip,
                            messagesSince: lastSyncDetermined,
                        },
                    },
                    'conversations-delta'
                );

                // Append results incrementally into the state
                allConversations = [...allConversations, ...conversationsPaged.conversations];

                // Update totalRecords for ending the loop condition#
                totalRecords = conversationsPaged.total;

                // Increment the offset (skip) for the next fetch
                skip += take;

                const updatedConversations = [...allConversations];

                queryClient.setQueryData<IConversations | undefined>(
                    ['conversations-delta'],
                    (oldData) => {
                        return {
                            ...(oldData || { total: 0, count: 0, conversations: [] }),
                            conversations: updatedConversations,
                        };
                    }
                );
            } while (allConversations.length < totalRecords);

            return allConversations;
        },

        /**
         * Updating the local store with the new conversation messages.
         * This functions is designed to run more often
         * @param conversations
         */
        onSuccess: (conversations) => {
            const existingConversations = queryClient.getQueryData<IConversation[]>([
                'conversations-storage',
            ]);

            if (!existingConversations) return;

            // Including a fix for Object/Array mismatch.
            const updatedConversations = [...Object.values(existingConversations)];

            conversations.forEach((conversation) => {
                const existingConversationIndex = updatedConversations.findIndex(
                    (convo) => convo.id === conversation.id
                );

                if (
                    existingConversationIndex > -1 &&
                    updatedConversations[existingConversationIndex]
                ) {
                    updatedConversations[existingConversationIndex] = {
                        ...(updatedConversations[existingConversationIndex] as IConversation),
                        messages: conversation.messages,
                    };
                } else {
                    setConversationsToUpdate([Number(conversation.id)]);
                }
            });

            const sortedConversations = conversationSortByNewestMessage(updatedConversations);

            // Update the store with the merged conversations
            queryClient.setQueryData<IConversation[]>(
                ['conversations-storage'],
                sortedConversations
            );

            const newSyncDate = dayjs().toISOString();
            if (typeof window !== 'undefined') {
                window.localStorage.setItem('conversationsLastSync', newSyncDate);
            }

            setLastSyncTimestamp(newSyncDate);

            setLastOptimisticUpdate();
        },
        // 1 Minute
        staleTime: 60,
    });
};
