import { useCallback, useEffect, useState } from 'react';
import { lfChatMessageQueue } from '../../../components/Chat/localstorage/lfAdminContracts';

export const useChatHasQueuedMessages = () => {
    const [queuedMessages, setQueuedMessages] = useState(0);

    const execMessages = useCallback(async () => {
        await lfChatMessageQueue.length().then((length) => {
            setQueuedMessages(length);
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            void execMessages();
        }, 5000);

        return () => clearInterval(interval);
    }, [execMessages]);

    return { queuedMessages };
};
