import { useMemo } from 'react';
import { useRouter } from 'next/router';

export const useAdminSVSceneDetector = () => {
    const { pathname } = useRouter();

    const isAdminPage: boolean = useMemo(() => {
        return pathname.startsWith('/admin');
    }, [pathname]);

    return { isAdminPage };
};
