import { create } from 'zustand';
import { IUser } from '../../../interfaces/user/user.model';

export type AuthorOrigin = 'automated' | 'self' | 'user';

export interface IConversationMessage {
    id: string;
    subject: string;
    unreadCount: number;
    address: string;
    company: string;
    lastMessage?: {
        body: string;
        sent: string | null;
        author: {
            name: string;
            origin: AuthorOrigin;
        };
    };
    contractId?: string;
    contractExpress?: boolean;
    recipients?: IUser[];
}

interface IConversationState {
    conversationId: string | null;
    setConversationId: (conversationId: string | null) => void;
    // addMessage: (message: IConversationMessage) => void;
    // updateMessage: (message: IConversationMessage) => void;
    filterText: string;
    setFilterText: (filterText: string) => void;
    // messages: IConversationMessage[];
    set: (state: Partial<IConversationState>) => void;
}

export const useConversationState = create<IConversationState>((set) => ({
    conversationId: null,
    setConversationId: (conversationId) => {
        set({ conversationId });
    },
    // addMessage: (message) => {
    //     set((state) => {
    //         return {
    //             messages: [...state.messages, message],
    //         };
    //     });
    // },
    // updateMessage: (message: IConversationMessage) => {
    //     set((state) => {
    //         return {
    //             messages: state.messages.map((oldMessage) => {
    //                 if (oldMessage.id === message.id) {
    //                     return message;
    //                 }
    //                 return oldMessage;
    //             }),
    //         };
    //     });
    // },
    filterText: '',
    setFilterText: (filterText) => {
        set({ filterText });
    },
    // messages: [],
    set: (state) => {
        set(state);
    },
}));
