import { useCallback } from 'react';
import { IConversation } from '../../../interfaces/conversation/conversation.model';
import { useQueryClient } from 'react-query';
import { useConversationsLoaderState } from '../state/conversationsLoader.state';
import { useConversationHandleStore } from './useConversationHandleStore';

export const useConsistencyUpdate = () => {
    const { setConversationsToUpdate } = useConversationsLoaderState();

    const queryClient = useQueryClient();

    const { conversations: conversationsStored } = useConversationHandleStore();

    const consistencyAddContractsToQueue = useCallback(
        (conversations: IConversation[]) => {
            // Extract all new conversation IDs3
            const newConversationIds: number[] = [];
            conversations.forEach((fetchedConvo) => {
                const existsInStore = conversationsStored.some(
                    (storedConvo) => storedConvo.id === fetchedConvo.id
                );
                if (!existsInStore) {
                    newConversationIds.push(Number(fetchedConvo.id));
                }
            });

            // Schedule Update
            setConversationsToUpdate(newConversationIds);
        },
        [conversationsStored, setConversationsToUpdate]
    );

    const consistencyRemoveContracts = useCallback(
        (conversations: IConversation[]) => {
            // Step A: Remove IDs from conversationsUpdated that are not in `conversations`
            const conversationsUpdated = conversationsStored.filter((storedConvo) =>
                conversations.some((fetchedConvo) => fetchedConvo.id === storedConvo.id)
            );

            // Update
            queryClient.setQueryData<IConversation[]>(
                ['conversations-storage'],
                conversationsUpdated
            );
        },
        [conversationsStored, queryClient]
    );

    return { consistencyRemoveContracts, consistencyAddContractsToQueue };
};
