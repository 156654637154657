import { IConversation } from '../../../interfaces/conversation/conversation.model';

export const getUnreadCount = (userId: string, conversations: IConversation[]): number => {
    return conversations?.reduce((totalUnread, conversation) => {
        const unreadMessages =
            conversation?.messages?.filter(
                (message) =>
                    !message.deleted &&
                    message.author.id !== userId &&
                    !message.read.find((user) => user.id === userId)
            ).length || 0;

        return totalUnread + unreadMessages;
    }, 0);
};
