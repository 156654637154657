import { useChatMessageQueue } from '../recoil/chatMessageQueue.state';
import { useEffect } from 'react';
import { useChatMessageQueueUpdate } from './useChatMessageQueueUpdate';

export const useChatMessageQueueService = () => {
    const { setLastSync } = useChatMessageQueue();

    const { sendMessageBatch } = useChatMessageQueueUpdate();

    useEffect(() => {
        const interval = setInterval(() => {
            void sendMessageBatch();
            setLastSync(new Date().toISOString());
        }, 5000);

        return () => clearInterval(interval);
    }, [sendMessageBatch, setLastSync]);
};
